import React from "react";
import OrderService from "../../services/OrderService";
import LocationService from "../../services/LocationService";
import AddressComponent from "./AddressComponent";
import { withTranslation } from "react-i18next";
import {Container, Col, Row, Form, Collapse, Button, Stack, Tabs, Tab, Spinner} from "react-bootstrap";
import SpinnerModal from "../../commons/modal/SpinnerModal";
import ErrorModal from "../../commons/modal/ErrorModal";
import DeviceService from "../../services/DeviceService";
import DeviceTypeService from "../../services/DeviceTypeService";
import NetworkService from "../../services/NetworkService";
import CountryService from "../../services/CountryService";
import Select from "react-select";
import CommentsComponent from "./CommentsComponent";
import OrderStateHistoryComponent from "./OrderStateHistoryComponent";
import comment from "../../commons/modal/CommentModal";
import confirm from "../../commons/modal/ConfirmModal";
import {formatErrorMessage, composeVendorFirmwareVersionsMessage} from "../../utils/stringUtils"
import MessageLogComponent from "./MessageLogComponent";
import SnippetService from "../../services/SnippetService";
import OrderHistoryComponent from "./OrderHistoryComponent";
import InfoModal from "../../commons/modal/InfoModal";
import LocationDevicesModal from "./LocationDevicesModal";
import {getCurrentUser} from "../../utils/storageUtils";

class OrderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderState: null,
            orderChanged: false,
            showSpinnerMessage: null,
            showConfirmMessage: null,
            showErrorMessage: null,
            showInfoMessage: null,
            showLocationMarketingData: false,
            showCompanyAddresses: false,
            showLocationAddress: false,
            data: {
                comments: []
            },
            stateHistory: [],
            stateHistoryLoading: false,
            messageLog: [],
            messageLogLoading: false,
            deviceTypes: [],
            deviceType: {},
            deviceTypeLoading: true,
            networks: [],
            countries: [],
            network: {},
            networksLoading: true,
            actionStarted: false,
            locationPinLoading: false,
            locationDevicesLoading: false,
            locationDevices: [],
            vendorCode: null,
            vendorFirmwareVersions: null,
            closingOrder: false
        }
    }

    componentDidMount() {
        if(this.props.id) this.getData();
    }

    getData = () => {
        this.showSpinner(this.props.t('common:modalMessage.getData'));
        CountryService.getAll().then((response) => {

            this.setState((prevState) => ({
                countries: response.data
            }));

            DeviceTypeService.getAll().then((response) => {

                this.setState((prevState) => ({
                    deviceTypes: response.data,
                    deviceTypeLoading: false
                }));

                NetworkService.getAll().then((response) => {

                    this.setState((prevState) => ({
                        networks: response.data,
                        networksLoading: false
                    }));

                    OrderService.getOrder(this.props.id).then((response) => {
                        this.hideSpinner();
                        this.setState((prevState) => ({
                            data: response.data,
                            deviceType: this.getDeviceType(response.data.deviceTypeId),
                            network: this.getNetwork(response.data.locationNetworkId),
                            orderState: response.data.state
                        }));
                    }, (error) => {
                        if (error.response.status === 403) {
                            this.hideSpinner();
                            document.location.href = "/orders";
                        } else {
                            this.showError(formatErrorMessage(error.response.data, error.response.status));
                        }
                    })

                }, (error) => {
                    this.showError(formatErrorMessage(error.response.data, error.response.status));
                });

            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });

        }, (error) => {
            this.showError(formatErrorMessage(error.response.data, error.response.status));
        });
    }

    getStateHistory = () => {
        this.setState((prevState) => ({
            stateHistory: [],
            stateHistoryLoading: true
        }));
        OrderService.getOrderStateHistory(this.props.id).then((response) => {
            this.setState((prevState) => ({
                stateHistory: response.data,
                stateHistoryLoading: false
            }));
        }, (error) => {
            this.setState((prevState) => ({
                stateHistoryLoading: false
            }));
            this.showError(formatErrorMessage(error.response.data, error.response.status));
        });
    }

    getMessageLog = () => {
        this.setState((prevState) => ({
            messageLog: [],
            messageLogLoading: true
        }));
        OrderService.getOrderMessageLog(this.props.id).then((response) => {
            this.setState((prevState) => ({
                messageLog: response.data,
                messageLogLoading: false
            }));
        }, (error) => {
            this.setState((prevState) => ({
                messageLogLoading: false
            }));
            this.showError(formatErrorMessage(error.response.data, error.response.status));
        });
    }

    handleDeviceTypeChange = (type) => {
        this.setState((prevState) => ({
            orderChanged: true,
            deviceType: type,
            data: {
                ...this.state.data,
                deviceType: type.name
            }
        }));
    }

    handleNetworkChange = (net) => {
        this.setState((prevState) => ({
            orderChanged: true,
            network: net,
            data: {
                ...this.state.data,
                locationNetwork: net.name
            }
        }));
    }

    getDeviceType = (id) => {
        let retVal = null;
        this.state.deviceTypes.forEach((t) => {
            if (t.id === id) retVal = t;
        });
        return retVal;
    }

    getNetwork = (id) => {
        let retVal = null;
        this.state.networks.forEach((t) => {
            if (t.id === id) retVal = t;
        });
        return retVal;
    }

    handleInputChange = (event) => {
        console.log("handleInputChange", event.target.name, event.target.value);
        this.setState((currState) => ({
            orderChanged: true,
            data: {
                ...currState.data,
                [event.target.name]: event.target.value
            }
        }));
    }

    toggleLocationMarketingData = () => {
        this.setState({
            showLocationMarketingData: !this.state.showLocationMarketingData
        });
    }

    toggleCompanyAddresses = () => {
        this.setState({
            showCompanyAddresses: !this.state.showCompanyAddresses
        });
    }

    toggleLocationAddress = () => {
        this.setState({
            showLocationAddress: !this.state.showLocationAddress
        });
    }

    showSpinner = (message) => {
        this.setState({
            showSpinnerMessage: message,
            showErrorMessage: null
        });
    }

    showError = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showErrorMessage: message
        });
    }

    showConfirm = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showConfirmMessage: message
        });
    }

    showLocationDevicesModal = (devices, vendorCode) => {
        this.setState({
            showSpinnerMessage: null,
            locationDevices: devices,
            vendorCode: vendorCode
        });
    }

    showInfo = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showInfoMessage: message
        });
    }

    hideSpinner = () => {
        this.setState({
            showSpinnerMessage: null
        });
    }

    hideError = () => {
        this.setState({
            showErrorMessage: null
        });
    }

    hideInfo = () => {
        this.setState({
            showInfoMessage: null
        });
    }

    hideConfirm = () => {
        this.setState({
            showConfirmMessage: null
        });
    }

    hideLocationDevicesModal = () => {
        if (this.state.closingOrder) this.closeOrder();
        this.setState({
            locationDevices: [],
            vendorCode: null,
            closingOrder: false
        });
    }

    startAction = () => {
        this.setState({
            actionStarted: true
        });
    }

    stopAction = () => {
        this.setState({
            actionStarted: false
        });
    }

    handleOrderUpdate = async () => {
        const { t } = this.props;
        if (await confirm(t('common:modalMessage.confirmTitle'), t('order:dialog.updateOrderQuestion'), t('common:button.yes'), t('common:button.cancel'))) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.updateOrder(this.props.id, this.state.data).then((success) => {
                this.setState({
                    orderState: this.state.data.state,
                    orderChanged: false,
                    showSpinnerMessage: null
                });
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    handleChangeTab = (tabName) => {
        if (tabName === "state-history-tab") this.getStateHistory();
        else if (tabName === "messages-tab") this.getMessageLog();
    }

    handleBeginOrder = async () => {
        const { t } = this.props;

        this.startAction();
        let firmwareVersions = this.state.vendorFirmwareVersions;
        if (firmwareVersions == null) {
            const firmwareVersionsResponse = await DeviceService.getVendorFirmwareVersions(this.props.id);
            if (firmwareVersionsResponse.status === 200) {
                firmwareVersions = firmwareVersionsResponse.data;
                this.setState({
                    vendorFirmwareVersions: firmwareVersionsResponse.data
                });
            }
        }
        const firmwareVersionsMessage = composeVendorFirmwareVersionsMessage(t("order:devices.requiredDevicesMessage"), firmwareVersions);
        this.stopAction();

        if (firmwareVersionsMessage) await confirm(t('order:vendorFirmwareVersionsMessage.title'), firmwareVersionsMessage, t('order:vendorFirmwareVersionsMessage.buttonLabel'), null, {showIcon: false});

        this.showSpinner(t('common:modalMessage.saveData'));
        OrderService.beginOrder(this.props.id).then((success) => {
            this.getData();
        }, (error) => {
            this.showError(formatErrorMessage(error.response.data, error.response.status));
        });
    }

    handleUnassignOrder = async () => {
        const { t } = this.props;
        const note = await comment(t('common:modalMessage.unassignTitle'), t('common:button.resign'), t('common:button.cancel'));
        if (note) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.unassignOrder(this.props.id, note).then((success) => {
                window.location.href = "/orders";
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    showLocationPin = () => {
        this.setState({
            locationPinLoading: true
        });
        LocationService.getPin(this.props.id).then((success) => {
            this.setState({
                locationPinLoading: false
            });
            this.showInfo("PIN: " + success.data);
        }, (error) => {
            this.setState({
                locationPinLoading: false
            });
            this.showError(formatErrorMessage(error.response.data, error.response.status));
        });
    }

    showLocationDevices = () => {
        this.setState({
            locationDevicesLoading: true
        });
        DeviceService.getLocationDevices(this.props.id).then((success) => {
            this.setState({
                locationDevicesLoading: false
            });
            this.showLocationDevicesModal(success.data.devices, success.data.vendor_code);
        }, (error) => {
            this.setState({
                locationDevicesLoading: false
            });
            this.showError(formatErrorMessage(error.response.data, error.response.status));
        });
        DeviceService.getVendorFirmwareVersions(this.props.id).then((success) => {
            this.setState({
                vendorFirmwareVersions: success.data
            });
        }, (error) => {
            console.error(formatErrorMessage(error.response.data, error.response.status));
        });
    }

    handleAddComment = async () => {
        const { t } = this.props;
        const note = await comment(t('common:modalMessage.commentTitle'), t('common:button.confirm'), t('common:button.cancel'));
        if (note) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.addComment(this.props.id, note).then((success) => {
                this.getData();
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    handleSuspendOrder = async () => {
        const { t } = this.props;
        const note = await comment(t('common:modalMessage.suspendTitle'), t('order:button.suspendOrder'), t('common:button.cancel'));
        if (note) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.suspendOrder(this.props.id, note).then((success) => {
                this.getData();
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    handleUnsuspendOrder = async () => {
        const { t } = this.props;
        if (await confirm(t('common:modalMessage.confirmTitle'), t('order:dialog.unsuspendQuestion'), t('order:button.unsuspendOrder'), t('common:button.cancel'))) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.unsuspendOrder(this.props.id).then((success) => {
                this.getData();
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    handleExportOrder = async () => {
        const { t } = this.props;
        if (await confirm(t('common:modalMessage.confirmTitle'), t('order:dialog.exportOrderQuestion'), t('common:button.send'), t('common:button.cancel'))) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.exportOrder(this.props.id).then((success) => {
                this.getData();
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    handleCloseOrder = async () => {
        this.setState({
            closingOrder: true
        });
        this.showLocationDevices();
    }

    closeOrder = async () => {
        const { t } = this.props;

        this.startAction();
        const closeOrderMessageSnippet = await SnippetService.getSnippet('pi_close_order_message');
        const locationDevices = await DeviceService.getLocationDevices(this.props.id);
        const closeMessage = this.composeCloseMessage(locationDevices.data);
        this.stopAction();

        if (closeMessage) await confirm(t('order:incompatibleDevicesHtmlWarning.title'), closeMessage, t('order:incompatibleDevicesHtmlWarning.buttonLabel'), null, {showIcon: false});

        if (await confirm(t('common:modalMessage.confirmTitle'), closeOrderMessageSnippet.data.content ? closeOrderMessageSnippet.data.content : t('order:dialog.closeOrderQuestion'), t('common:button.yes'), t('common:button.cancel'))) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.closeOrder(this.props.id).then((success) => {
                this.getData();
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    composeCloseMessage = (devicesResponse) => {
        if (!this.state.data || !devicesResponse) return null;
        const { t } = this.props;

        const expectedDeviceCount = this.state.data.newDeviceCount + this.state.data.upgradeDeviceCount;
        if (expectedDeviceCount === 0) return null;
        const compatibleDeviceCount = this.countCompatibleDevices(devicesResponse);

        return compatibleDeviceCount < expectedDeviceCount ? t('order:incompatibleDevicesHtmlWarning.part1')
            + compatibleDeviceCount + t('order:incompatibleDevicesHtmlWarning.part2')
            + expectedDeviceCount + t('order:incompatibleDevicesHtmlWarning.part3') : null;
    }

    countCompatibleDevices = (devicesResponse) => {
        if (devicesResponse == null) return 0;
        let compatibleDevicesCount = 0;
        devicesResponse.devices.forEach((device) => {
            if (devicesResponse.vendor_code === device.vendor_code && device.is_mpos_value_compatible && device.heartbeat) {
                if (this.state.data.orderAcquisitionType === "DRS") {
                    compatibleDevicesCount++;
                } else {
                    if (device.is_firmware_compatible) compatibleDevicesCount++;
                }
            }
        });
        return compatibleDevicesCount;
    }

    handleResignFromOrder = async () => {
        const { t } = this.props;
        const note = await comment(t('common:modalMessage.clientResignationTitle'), t('order:button.resignFromOrder'), t('common:button.cancel'));
        if (note) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.resignFromOrder(this.props.id, note).then((success) => {
                this.getData();
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    handleArchiveOrder = async () => {
        const { t } = this.props;
        const note = await confirm(t('common:modalMessage.confirmTitle'), t('order:dialog.archiveOrderQuestion'), t('common:button.yes'), t('common:button.cancel'));
        if (note) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.archiveOrder(this.props.id).then((success) => {
                this.getData();
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    handleDeArchiveOrder = async () => {
        const { t } = this.props;
        const note = await confirm(t('common:modalMessage.confirmTitle'), t('order:dialog.deArchiveOrderQuestion'), t('common:button.yes'), t('common:button.cancel'));
        if (note) {
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.deArchiveOrder(this.props.id).then((success) => {
                this.getData();
            }, (error) => {
                this.showError(formatErrorMessage(error.response.data, error.response.status));
            });
        }
    }

    render() {
        const currentUser = getCurrentUser();
        const { t } = this.props;

        return(<>
            <LocationDevicesModal modalShow={this.state.vendorCode != null} close={this.hideLocationDevicesModal} devices={this.state.locationDevices} vendorCode={this.state.vendorCode} versions={this.state.vendorFirmwareVersions} timeZone={this.props.timeZone} acquisitionType={this.state.data.orderAcquisitionType}/>
            <InfoModal modalShow={this.state.showInfoMessage != null} close={this.hideInfo} body={this.state.showInfoMessage} title={t('common:modalMessage.infoTitle')}/>
            <ErrorModal modalShow={this.state.showErrorMessage != null} close={this.hideError} body={this.state.showErrorMessage} title={t('common:modalMessage.errorTitle')}/>
            <SpinnerModal modalShow={this.state.showSpinnerMessage != null} title={this.state.showSpinnerMessage}/>
            <Container className="customForm">
                <Row className="panel-heading">
                    <Col sm={12}>
                        <h4>{t('order:header', {acq: this.state.data.orderAcquisitionType, num: this.props.id, type: this.state.data.orderType})}</h4>
                    </Col>
                </Row>
                <br/>
                <Form onChange={this.handleInputChange} validated={true} noValidate>
                    <Row>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:orderForm.deviceType')}</Form.Label></Col>
                            <Col md={12}>
                                <Select
                                    options={this.state.deviceTypes}
                                    onChange={(deviceType) => this.handleDeviceTypeChange(deviceType)}
                                    isLoading={this.state.deviceTypeLoading}
                                    value={this.state.deviceType}
                                    getOptionLabel={(option) => `${option.name}`}
                                    placeholder={t('order:orderForm.selectDeviceType')}
                                    noOptionsMessage={() => t('order:orderForm.noDeviceTypesAvailable')}
                                    loadingMessage={() => t('common:loading')}
                                    isDisabled={!currentUser.admin}
                                />
                            </Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:orderForm.state')}</Form.Label></Col>
                            <Col md={12}>
                                <Form.Select name="state" value={this.state.data.state} disabled={!currentUser.admin}>
                                    <option value={"CREATED"}>{t('orders:state.CREATED')}</option>
                                    <option value={"ASSIGNED"}>{t('orders:state.ASSIGNED')}</option>
                                    <option value={"STARTED"}>{t('orders:state.STARTED')}</option>
                                    <option value={"SUSPENDED"}>{t('orders:state.SUSPENDED')}</option>
                                    <option value={"CLOSED"}>{t('orders:state.CLOSED')}</option>
                                    <option value={"RESIGNED"}>{t('orders:state.RESIGNED')}</option>
                                    <option value={"REJECTED"}>{t('orders:state.REJECTED')}</option>
                                </Form.Select>
                            </Col>
                        </Col>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.newDeviceCount')}</Form.Label>
                            <Form.Control type="text" name="newDeviceCount" value={this.state.data.newDeviceCount} pattern="[0-9]+" required/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.upgradeDeviceCount')}</Form.Label>
                            <Form.Control type="text" name="upgradeDeviceCount" value={this.state.data.upgradeDeviceCount} pattern="[0-9]+" required/>
                        </Form.Group>
                    </Row>
                    <br/>
                    <Row>
                        <Form.Group as={Col} md="2">
                            <Form.Label>{t('order:orderForm.externalId')}</Form.Label>
                            <Form.Control type="text" name="externalId" value={this.state.data.externalId}  disabled={!currentUser.admin}/>
                        </Form.Group>
                        <Form.Group as={Col} md="5">
                            <Form.Label>{t('order:orderForm.preferredDealer')}</Form.Label>
                            <Form.Control type="text" name="orderPreferredDealer" value={this.state.data.orderPreferredDealer} disabled={!currentUser.admin}/>
                        </Form.Group>
                        <Form.Group as={Col} md="5">
                            <Form.Label>{t('order:orderForm.assignedDealerEmail')}</Form.Label>
                            <Form.Control type="text" name="assignedDealerEmail" value={this.state.data.assignedDealerEmail} disabled={!currentUser.admin}/>
                        </Form.Group>
                    </Row>
                    <hr/>
                    <Row>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.paymentMethod.label')}</Form.Label>
                            <Form.Select name="paymentMethod" value={this.state.data.paymentMethod}>
                                <option value={""}>{""}</option>
                                <option value={"cash"}>{t('order:orderForm.paymentMethod.cash')}</option>
                                <option value={"credit"}>{t('order:orderForm.paymentMethod.credit')}</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.marketingActions')}</Form.Label>
                            <Form.Control type="text" name="marketingActions" value={this.state.data.marketingActions} disabled={!currentUser.admin}/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.orderSource')}</Form.Label>
                            <Form.Control type="text" name="orderSource" value={this.state.data.orderSource} disabled={!currentUser.admin} required/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.orderProducer')}</Form.Label>
                            <Form.Control type="text" name="orderProducer" value={this.state.data.orderProducer} disabled={!currentUser.admin} required/>
                        </Form.Group>
                    </Row>
                    <br/>
                    <Row>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.ordererName')}</Form.Label>
                            <Form.Control type="text" name="ordererName" value={this.state.data.ordererName} disabled={!currentUser.admin}/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.ordererSurname')}</Form.Label>
                            <Form.Control type="text" name="ordererSurname" value={this.state.data.ordererSurname} disabled={!currentUser.admin}/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.ordererEmail')}</Form.Label>
                            <Form.Control type="text" name="ordererEmail" value={this.state.data.ordererEmail} disabled={!currentUser.admin}/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:orderForm.ordererPhone')}</Form.Label>
                            <Form.Control type="text" name="ordererPhone" value={this.state.data.ordererPhone} disabled={!currentUser.admin}/>
                        </Form.Group>
                    </Row>
                </Form>
            </Container>
            <Container className="customForm mt-2">
                <Row className="panel-heading">
                    <Col sm={12}>
                        <h4>{t('order:locationForm.header')}</h4>
                    </Col>
                </Row>
                <br/>
                <Form onChange={this.handleInputChange} validated={true} noValidate>
                    <Row>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:locationForm.network')}</Form.Label></Col>
                            <Col md={12}>
                                <Select
                                    options={this.state.networks}
                                    onChange={(network) => this.handleNetworkChange(network)}
                                    isLoading={this.state.networksLoading}
                                    value={this.state.network}
                                    getOptionLabel={(option) => `${option.name === 'INDEPENDENT' ? t('order:locationForm.independent') : option.name}`}
                                    placeholder={t('order:locationForm.selectNetwork')}
                                    noOptionsMessage={() => t('order:locationForm.noNetworksAvailable')}
                                    loadingMessage={() => t('common:loading')}
                                    isDisabled={!currentUser.admin}
                                    required
                                />
                            </Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:locationForm.idCrm')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="locationIdCrm" value={this.state.data.locationIdCrm} required/></Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName backofficeIdLabel">{t('order:locationForm.backofficeId')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="locationBackofficeId" value={this.state.data.locationBackofficeId} disabled={!currentUser.admin}/></Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:locationForm.externalId')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="locationExternalId" value={this.state.data.locationExternalId} disabled={!currentUser.admin}/></Col>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:locationForm.name')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="locationName" value={this.state.data.locationName} required/></Col>
                        </Col>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:locationForm.shortName')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="locationShortName" value={this.state.data.locationShortName}/></Col>
                        </Col>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:locationForm.fullName')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="locationFullName" value={this.state.data.locationFullName}/></Col>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={12}>
                            <Button variant="secondary" size="sm" className="mb-4" onClick={this.toggleLocationMarketingData}>
                                {this.state.showLocationMarketingData ? t('order:button.hideLocationMarketingData') : t('order:button.showLocationMarketingData')}
                            </Button>
                            {' '}
                            <Button variant="secondary" size="sm" className="mb-4" onClick={this.toggleLocationAddress}>
                                {this.state.showLocationAddress ? t('order:button.hideLocationAddress') : t('order:button.showLocationAddress')}
                            </Button>
                            {' '}
                            <Button variant="secondary" size="sm" className="mb-4" onClick={this.showLocationPin} disabled={this.state.locationPinLoading}>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!this.state.locationPinLoading}/>
                                {' '}
                                {t('order:button.showLocationPin')}
                            </Button>
                            {this.state.data.locationBackofficeId && (<>
                                {' '}
                                <Button variant="secondary" size="sm" className="mb-4" onClick={this.showLocationDevices} disabled={this.state.locationDevicesLoading}>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!this.state.locationDevicesLoading}/>
                                    {' '}
                                    {t('order:button.showLocationDevices')}
                                </Button>
                            </>)}
                        </Col>
                    </Row>
                    <Collapse in={this.state.showLocationMarketingData}>
                        <div id="showLocationMarketingData">
                            <Row>
                                <Col md={4}>
                                    <Col md={12}><Form.Label className="labelName">{t('order:locationForm.category')}</Form.Label></Col>
                                    <Col md={12}><Form.Control type="text" name="locationCategory" value={this.state.data.locationCategory}/></Col>
                                </Col>
                                <Col md={4}>
                                    <Col md={12}><Form.Label className="labelName">{t('order:locationForm.region')}</Form.Label></Col>
                                    <Col md={12}><Form.Control type="text" name="locationRegion" value={this.state.data.locationRegion}/></Col>
                                </Col>
                                <Col md={4}>
                                    <Col md={12}><Form.Label className="labelName">{t('order:locationForm.segment')}</Form.Label></Col>
                                    <Col md={12}><Form.Control type="text" name="locationSegment" value={this.state.data.locationSegment}/></Col>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={4}>
                                    <Col md={12}><Form.Label className="labelName">{t('order:locationForm.district')}</Form.Label></Col>
                                    <Col md={12}><Form.Control type="text" name="locationDistrict" value={this.state.data.locationDistrict}/></Col>
                                </Col>
                                <Col md={4}>
                                    <Col md={12}><Form.Label className="labelName">{t('order:locationForm.uik')}</Form.Label></Col>
                                    <Col md={12}><Form.Control type="text" name="locationUik" value={this.state.data.locationUik}/></Col>
                                </Col>
                                <Col md={4}>
                                    <Col md={12}><Form.Label className="labelName">{t('order:locationForm.quartile')}</Form.Label></Col>
                                    <Col md={12}><Form.Control type="text" name="locationQuartile" value={this.state.data.locationQuartile}/></Col>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                    <br/>
                    <Collapse in={this.state.showLocationAddress}>
                        <div id="showLocationAddress">
                            <AddressComponent onChange={this.handleInputChange}
                                              countries={this.state.countries}
                                              prefix="location"
                                              showContact={false}
                                              street={this.state.data.locationStreet}
                                              building={this.state.data.locationBuilding}
                                              flat={this.state.data.locationFlat}
                                              city={this.state.data.locationCity}
                                              postalCode={this.state.data.locationPostalCode}
                                              country={this.state.data.locationCountry}/>
                        </div>
                    </Collapse>
                </Form>
            </Container>
            <Container className="customForm mt-2">
                <Row className="panel-heading">
                    <Col sm={12}>
                        <h4>{t('order:companyForm.header')}</h4>
                    </Col>
                </Row>
                <br/>
                <Form onChange={this.handleInputChange} validated={true} noValidate>
                    <Row>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:companyForm.nip')}</Form.Label>
                            <Form.Control type="text" name="companyNip" value={this.state.data.companyNip} required/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:companyForm.idCrm')}</Form.Label>
                            <Form.Control type="text" name="companyIdCrm" value={this.state.data.companyIdCrm} required/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:companyForm.regon')}</Form.Label>
                            <Form.Control type="text" name="companyRegon" value={this.state.data.companyRegon}/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:companyForm.krs')}</Form.Label>
                            <Form.Control type="text" name="companyKrs" value={this.state.data.companyKrs}/>
                        </Form.Group>
                    </Row>
                    <br/>
                    <Row>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:companyForm.nipCession')}</Form.Label>
                            <Form.Control type="text" name="companyNipCession" value={this.state.data.companyNipCession} disabled={!currentUser.admin}/>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label>{t('order:companyForm.externalId')}</Form.Label>
                            <Form.Control type="text" name="companyExternalId" value={this.state.data.companyExternalId} disabled={!currentUser.admin}/>
                        </Form.Group>
                    </Row>
                    <br/>
                    <Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{t('order:companyForm.name')}</Form.Label>
                            <Form.Control type="text" name="companyName" value={this.state.data.companyName} required/>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{t('order:companyForm.fullName')}</Form.Label>
                            <Form.Control type="text" name="companyFullName" value={this.state.data.companyFullName}/>
                        </Form.Group>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <Col md={12}><Form.Label className="labelName">{t('order:companyForm.legalForm')}</Form.Label></Col>
                            <Col md={12}>
                                <Form.Select name="companyLegalForm" value={this.state.data.companyLegalForm}>
                                    <option value={"ID"}>{t('common:legalForm.id')}</option>
                                    <option value={"SD"}>{t('common:legalForm.sd')}</option>
                                    <option value={"SJ"}>{t('common:legalForm.sj')}</option>
                                    <option value={"SC"}>{t('common:legalForm.sc')}</option>
                                    <option value={"SK"}>{t('common:legalForm.sk')}</option>
                                    <option value={"KA"}>{t('common:legalForm.ka')}</option>
                                    <option value={"SP"}>{t('common:legalForm.sp')}</option>
                                    <option value={"SA"}>{t('common:legalForm.sa')}</option>
                                    <option value={"ZO"}>{t('common:legalForm.zo')}</option>
                                    <option value={"ZOK"}>{t('common:legalForm.zok')}</option>
                                </Form.Select>
                            </Col>
                        </Col>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{t('order:companyForm.bankAccountNumber')}</Form.Label>
                            <Form.Control type="text" name="companyBankAccountNumber" value={this.state.data.companyBankAccountNumber}/>
                        </Form.Group>
                    </Row>
                </Form>
                <br/>
                <Row>
                    <Col md={12}>
                        <Button variant="secondary" size="sm" className="mb-4" onClick={this.toggleCompanyAddresses}>
                            {this.state.showCompanyAddresses ? t('order:button.hideCompanyAddresses') : t('order:button.showCompanyAddresses')}
                        </Button>
                    </Col>
                </Row>
                <Collapse in={this.state.showCompanyAddresses}>
                    <div id="showCompanyAddresses">
                        <Form onChange={this.handleInputChange} validated={true} noValidate>
                            <AddressComponent onChange={this.handleInputChange}
                                              countries={this.state.countries}
                                              title={t('order:companyForm.billingAddress')}
                                              prefix="companyBilling"
                                              showContact={false}
                                              street={this.state.data.companyBillingStreet}
                                              building={this.state.data.companyBillingBuilding}
                                              flat={this.state.data.companyBillingFlat}
                                              city={this.state.data.companyBillingCity}
                                              postalCode={this.state.data.companyBillingPostalCode}
                                              country={this.state.data.companyBillingCountry}/>
                            <br/>
                            <AddressComponent onChange={this.handleInputChange}
                                              countries={this.state.countries}
                                              title={t('order:companyForm.headquartersAddress')}
                                              prefix="companyHeadquarters"
                                              showContact={false}
                                              street={this.state.data.companyHeadquartersStreet}
                                              building={this.state.data.companyHeadquartersBuilding}
                                              flat={this.state.data.companyHeadquartersFlat}
                                              city={this.state.data.companyHeadquartersCity}
                                              postalCode={this.state.data.companyHeadquartersPostalCode}
                                              country={this.state.data.companyHeadquartersCountry}/>
                            <br/>
                            <AddressComponent onChange={this.handleInputChange}
                                              countries={this.state.countries}
                                              title={t('order:companyForm.contactAddress')}
                                              prefix="companyContact"
                                              showContact={true}
                                              name={this.state.data.companyContactName}
                                              phone={this.state.data.companyContactPhone}
                                              email={this.state.data.companyContactEmail}
                                              street={this.state.data.companyContactStreet}
                                              building={this.state.data.companyContactBuilding}
                                              flat={this.state.data.companyContactFlat}
                                              city={this.state.data.companyContactCity}
                                              postalCode={this.state.data.companyContactPostalCode}
                                              country={this.state.data.companyContactCountry}/>
                        </Form>
                    </div>
                </Collapse>
            </Container>
            <Container className="customForm mt-2">
                <Row className="panel-heading">
                    <Col sm={12}>
                        <h4>{t('order:merchantForm.header')}</h4>
                    </Col>
                </Row>
                <br/>
                <Form onChange={this.handleInputChange} validated={true} noValidate>
                    <Row>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:merchantForm.name')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="merchantName" value={this.state.data.merchantName}/></Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:merchantForm.surname')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="merchantSurname" value={this.state.data.merchantSurname}/></Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:merchantForm.pesel')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="merchantPesel" value={this.state.data.merchantPesel}/></Col>
                        </Col>
                        <Col md={3}>
                            <Col md={12}><Form.Label className="labelName">{t('order:merchantForm.documentNumber')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="merchantDocumentNumber" value={this.state.data.merchantDocumentNumber}/></Col>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:merchantForm.email')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="merchantEmail" value={this.state.data.merchantEmail} required/></Col>
                        </Col>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:merchantForm.phone')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="merchantPhone" value={this.state.data.merchantPhone}/></Col>
                        </Col>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:merchantForm.login')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name="merchantLogin" value={this.state.data.merchantLogin}/></Col>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <Container className="buttonBar mt-4">
                <Stack direction="horizontal" gap={3}>
                    {(currentUser.admin || (this.state.orderState === "CREATED" || this.state.orderState === "ASSIGNED" || this.state.orderState === "STARTED" || this.state.orderState === "SUSPENDED")) && (<>
                        <Button onClick={this.handleOrderUpdate} disabled={!this.state.orderChanged || this.state.actionStarted}>{t('common:button.save')}</Button>
                    </>)}
                    {currentUser.dealer && this.state.orderState === "ASSIGNED" && currentUser.email === this.state.data.assignedDealerEmail && (<>
                        <Button variant="success" onClick={this.handleBeginOrder} disabled={this.state.actionStarted}>{t('common:button.begin')}</Button>
                        <Button variant="danger" onClick={this.handleUnassignOrder} disabled={this.state.actionStarted}>{t('common:button.resign')}</Button>
                    </>)}
                    {currentUser.dealer && this.state.orderState === "STARTED" && currentUser.email === this.state.data.assignedDealerEmail && (<>
                        <Button variant="warning" onClick={this.handleSuspendOrder} disabled={this.state.actionStarted}>{t('order:button.suspendOrder')}</Button>
                    </>)}
                    {currentUser.dealer && this.state.orderState === "SUSPENDED" && currentUser.email === this.state.data.assignedDealerEmail && (<>
                        <Button variant="success" onClick={this.handleUnsuspendOrder} disabled={this.state.actionStarted}>{t('order:button.unsuspendOrder')}</Button>
                    </>)}
                    {currentUser.dealer && this.state.orderState === "STARTED" && currentUser.email === this.state.data.assignedDealerEmail && (<>
                        <Button variant="danger" onClick={this.handleResignFromOrder} disabled={this.state.actionStarted}>{t('order:button.resignFromOrder')}</Button>
                    </>)}
                    {(currentUser.admin || (currentUser.dealer && currentUser.email === this.state.data.assignedDealerEmail)) && !this.state.data.exportedAt && this.state.orderState === "STARTED" && (<>
                        <Button variant="warning" onClick={this.handleExportOrder} disabled={this.state.orderChanged || this.state.actionStarted}>{t('order:button.exportOrder')}</Button>
                    </>)}
                    {(currentUser.admin || (currentUser.dealer && currentUser.email === this.state.data.assignedDealerEmail)) && this.state.orderState === "STARTED" && this.state.data.exportedAt && (<>
                        <Button variant="success" onClick={this.handleCloseOrder} disabled={this.state.actionStarted}>{t('order:button.closeOrder')}</Button>
                    </>)}
                    {currentUser.admin && this.state.data.orderIsArchived && (<>
                        <Button variant="warning" onClick={this.handleDeArchiveOrder} disabled={this.state.actionStarted}>{t('order:button.deArchiveOrder')}</Button>
                    </>)}
                    {currentUser.admin && !this.state.data.orderIsArchived && (<>
                        <Button variant="danger" onClick={this.handleArchiveOrder} disabled={this.state.actionStarted}>{t('order:button.archiveOrder')}</Button>
                    </>)}
                </Stack>
            </Container>
            <Container style={{paddingRight:0 , paddingLeft: 0}}>
                <Tabs onSelect={this.handleChangeTab} defaultActiveKey="history-tab" id="order-tabs-id" className="mt-4">
                    <Tab eventKey="history-tab" title={t('order:historyForm.header')}>
                        <OrderHistoryComponent timeZone={this.props.timeZone} createdAt={this.state.data.createdAt} startedAt={this.state.data.startedAt} exportedAt={this.state.data.exportedAt} closedAt={this.state.data.closedAt} resignedAt={this.state.data.resignedAt} updatedAt={this.state.data.updatedAt}/>
                    </Tab>
                    <Tab eventKey="comments-tab" title={t('order:commentsForm.header') + (this.state.data.comments ? ' (' + this.state.data.comments.length + ')' : '')}>
                        <CommentsComponent timeZone={this.props.timeZone} data={this.state.data.comments} showAddCommentButton={currentUser.dealer && currentUser.email === this.state.data.assignedDealerEmail} onAddComment={this.handleAddComment}/>
                    </Tab>
                    <Tab eventKey="state-history-tab" title={t('order:stateHistory.title')}>
                        <OrderStateHistoryComponent timeZone={this.props.timeZone} data={this.state.stateHistory} dataLoading={this.state.stateHistoryLoading}/>
                    </Tab>
                    {currentUser.admin && (
                        <Tab eventKey="messages-tab" title={t('order:messagesForm.header')}>
                            <MessageLogComponent timeZone={this.props.timeZone} data={this.state.messageLog} dataLoading={this.state.messageLogLoading}/>
                        </Tab>
                    )}
                </Tabs>
            </Container>
        </>);
    }
}
export default withTranslation() (OrderComponent);
