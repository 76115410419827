import React from "react";
import OrderService from "../../services/OrderService";
import {withTranslation} from "react-i18next";
import {Container, Col, Row, Form, Alert, Button, Stack} from "react-bootstrap";
import SpinnerModal from "../../commons/modal/SpinnerModal";
import ErrorModal from "../../commons/modal/ErrorModal";
import InfoModal from "../../commons/modal/InfoModal";
import FileService from "../../services/FileService";
import {Navigate} from "react-router-dom";
import {getCurrentUser} from "../../utils/storageUtils";

class ImportComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: "",
            errors: [],
            showSpinnerMessage: null,
            showInfoMessage: null,
            showErrorMessage: null
        }
    }

    showSpinner = (message) => {
        this.setState({
            showSpinnerMessage: message,
            showErrorMessage: null
        });
    }

    showError = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showErrorMessage: message
        });
    }

    showInfo = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showInfoMessage: message
        });
    }

    hideSpinner = () => {
        this.setState({
            showSpinnerMessage: null
        });
    }

    hideError = () => {
        this.setState({
            showErrorMessage: null
        })
    }

    hideInfo = () => {
        this.setState({
            showInfoMessage: null
        })
    }

    handleFileChange = (event) => {
        if(event.target.files.length > 0) {
            this.setState({
                file: event.target.files[0]
            });
        } else {
            this.setState({
                file: ""
            });
        }
    }

    handleGetImportExampleFile = () => {
        this.showSpinner(this.props.t('common:modalMessage.getData'));
        FileService.getExampleImportFile().then((success) => {
            this.hideSpinner();
            this.downloadFromResponse(success);
        }, (error) => {
            this.showError(error.response.data + " (" + error.response.status + ")");
        });
    }

    downloadFromResponse = (response) => {
        const fileUrl = "data:" + response.data.mediaType + ";base64," + response.data.data;
        fetch(fileUrl).then(response => response.blob()).then(blob => {
            const link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, {type: response.data.mediaType});
            link.download = response.data.fileName + "." + response.data.fileType;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    handleFileUpload = (event) => {
        const file = this.state.file;
        if (file) {
            this.setState({
                errors: []
            });
            const formData = new FormData();
            formData.append("file", file);
            this.showSpinner(this.props.t('common:modalMessage.saveData'));
            OrderService.importOrders(formData).then((success) => {
                this.setState({
                    file: ""
                });
                this.showInfo(success.data);
            }, (error) => {
                this.hideSpinner();
                this.setState({
                    errors: error.response.data
                });
            });
        }
    }

    render() {
        const currentUser = getCurrentUser();
        if (!currentUser || !currentUser.operator) return <Navigate to="/" replace />;

        const { t } = this.props;
        return(<>
            <InfoModal modalShow={this.state.showInfoMessage != null} close={this.hideInfo} body={this.state.showInfoMessage} title={t('common:modalMessage.infoTitle')}/>
            <ErrorModal modalShow={this.state.showErrorMessage != null} close={this.hideError} body={this.state.showErrorMessage} title={t('common:modalMessage.errorTitle')}/>
            <SpinnerModal modalShow={this.state.showSpinnerMessage != null} title={this.state.showSpinnerMessage}/>
            <Container className="customForm">
                <Row className="panel-heading">
                    <Col>
                        <h4>{t('orders_import:header')}</h4>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Form onSubmit={this.handleFileUpload}>
                            <Stack direction="horizontal" gap={3}>
                                <Form.Control type="file" id='file' name="file" accept=".xlsx" onChange={this.handleFileChange} />
                                <Button onClick={this.handleFileUpload} disabled={this.state.file === ""}>{t('common:button.import')}</Button>
                            </Stack>
                        </Form>
                    </Col>
                    <Col md={3}>
                        <Button variant="secondary" onClick={this.handleGetImportExampleFile}>{t('orders_import:button.example')}</Button>
                    </Col>
                </Row>
                {this.state.errors.length > 0 && (<>
                    <br/>
                    <Alert variant="danger">
                        {this.state.errors.map((object, index) => (<p key={index}>{object}</p>))}
                    </Alert>
                </>)}
            </Container>
        </>);
    }
}
export default withTranslation() (ImportComponent);