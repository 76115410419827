import React from 'react';
import {withTranslation} from "react-i18next";
import { Modal, Button, Table, Alert } from 'react-bootstrap';
import i18n from "../../messages/i18n";
import {composeVendorFirmwareVersionsMessage} from "../../utils/stringUtils";

const LocationDevicesModal = (props) => {
    const dateFormatOptions = {
        formatMatcher: "basic",
        year: 'numeric',
        month: 'numeric',
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
        timeZone: props.timeZone
    };

    let rowNum = 1;

    return (
        <Modal show={props.modalShow} onHide={props.close} dialogClassName={'location-devices-modal'}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.t('common:modalMessage.devicesTitle')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant={'warning'}>
                    <p dangerouslySetInnerHTML={{__html:composeVendorFirmwareVersionsMessage(props.t("order:devices.requiredDevicesMessage"), props.versions)}}/>
                </Alert>
                <Table striped responsive bordered size="sm">
                    <thead><tr>
                        <th><b>{props.t('order:devices.columns.rowNum')}</b></th>
                        <th><b>{props.t('order:devices.columns.name')}</b></th>
                        <th><b>{props.t('order:devices.columns.heartbeat')}</b></th>
                        <th><b>{props.t('order:devices.columns.firmware')}</b></th>
                        <th><b>{props.t('order:devices.columns.tokens')}</b></th>
                        <th><b>{props.t('order:devices.columns.number')}</b></th>
                    </tr></thead>
                    <tbody>
                    {props.devices && props.devices.map((device) => (<tr key={device.mcuid}>
                        <td>{rowNum++}</td>
                        <td>{device.company + ' ' + device.model}</td>
                        <td>
                            {device.heartbeat && Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(device.heartbeat))}
                            {!device.heartbeat && (device.vendor_code === props.vendorCode || (device.vendor_code === "KCPOS" && props.vendorCode === "MRITM") || (device.vendor_code === "MRITM" && props.vendorCode === "KCPOS")) && (<>
                                <span style={{color:'red'}}>{props.t('order:devices.noHeartbeat')}</span>
                            </>)}
                        </td>
                        <td>
                            {props.acquisitionType !== "DRS" && (<>
                                FW: {device.firmware_value}<br/>
                                {(device.vendor_code === props.vendorCode || (device.vendor_code === "KCPOS" && props.vendorCode === "MRITM") || (device.vendor_code === "MRITM" && props.vendorCode === "KCPOS")) && device.is_firmware_compatible && (<><span style={{color: 'green'}}>{props.t('order:devices.compatible')}</span><br/></>)}
                                {(device.vendor_code === props.vendorCode || (device.vendor_code === "KCPOS" && props.vendorCode === "MRITM") || (device.vendor_code === "MRITM" && props.vendorCode === "KCPOS")) && !device.is_firmware_compatible && (<><span style={{color: 'red'}}>{props.t('order:devices.notCompatible')}</span><br/></>)}
                            </>)}
                            POS: {device.mpos_value}<br/>
                            {(device.vendor_code === props.vendorCode || (device.vendor_code === "KCPOS" && props.vendorCode === "MRITM") || (device.vendor_code === "MRITM" && props.vendorCode === "KCPOS")) && device.is_mpos_value_compatible && (<span style={{color: 'green'}}>{props.t('order:devices.compatible')}</span>)}
                            {(device.vendor_code === props.vendorCode || (device.vendor_code === "KCPOS" && props.vendorCode === "MRITM") || (device.vendor_code === "MRITM" && props.vendorCode === "KCPOS")) && !device.is_mpos_value_compatible && (<span style={{color: 'red'}}>{props.t('order:devices.notCompatible')}</span>)}
                        </td>
                        <td>{device.active_token_cnt}</td>
                        <td>{device.unique}<br/>{device.mcuid}</td>
                    </tr>))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.close}>{props.t('common:button.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withTranslation() (LocationDevicesModal);
