import ContentComponent from './components/ContentComponent';
import NavbarComponent from './components/NavbarComponent'
import LoginComponent from './components/login/LoginComponent';
import {useNavigation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import UserService from "./services/UserService";
import {useState} from "react";
import {getAuthData, getInstanceInfo, removeAuthData, setInstanceInfo} from "./utils/storageUtils";
import {getUserTimeZone} from "./utils/timeZoneUtils";
import MaintenanceService from "./services/MaintenanceService";

const App = () => {
  const navigation = useNavigation();
  const { i18n } = useTranslation();
  const [ timeZone, setTimeZone ] = useState(getUserTimeZone());

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => {});
    if (getAuthData()) UserService.changeUserPreferences(lang, timeZone).then(() => {},() => {});
  }

  const handleLogout = () => {
    removeAuthData();
    document.location.href = "/";
  }

  // change from drop-down
  const handleTimeZoneChange = (tz) => {
    setTimeZone(tz);
    if (getAuthData()) UserService.changeUserPreferences(i18n.language, tz).then(() => {},() => {});
  }

  // change after login
  const changeTimeZone = (tz) => {
    setTimeZone(tz);
  }

  const loadInstanceInfo = async () => {
    return await MaintenanceService.getInstanceInfo().then((success) => {
      setInstanceInfo(success.data);
    });
  }

  if (!getInstanceInfo()) loadInstanceInfo().then(() => {});

  return (<>
    <NavbarComponent lang={i18n.language} onChangeLang={handleChangeLanguage} timeZone={timeZone} onTimeZoneChange={handleTimeZoneChange} onLogout={handleLogout}></NavbarComponent>
    {getAuthData() ? (
        <div id="content" className={navigation.state === "loading" ? "loading" : ""}>
            <ContentComponent timeZone={timeZone}/>
        </div>
    ) : (
        <LoginComponent onTimeZoneChange={changeTimeZone}/>
    )}
  </>);
}

export default App;
